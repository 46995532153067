import { IUser, IGif } from '@giphy/js-types'

const isFromUpload = (user: IUser): user is IUser & { source_image_url: string } => {
    // @ts-expect-error guessing these props come from the upload flow
    return user.source_image_url !== undefined
}

export const getSourceUrl = ({ source_post_url, user }: IGif) => {
    return source_post_url || user.website_url || (isFromUpload(user) ? user.source_image_url : '')
}
