'use client'
import { giphyLightGrey } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { useState } from 'react'
import { CloseIcon, EllipsisIcon } from 'ui/src/icons/utility'

const desktopGifWidth = 500
export const formatSize = (size = 0, decimals = 0) => {
    if (size === 0) return '0 Bytes'

    const k = 1000 // or 1024 for binary
    const dm = decimals + 1 || 3
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    const filesize = size / Math.pow(k, i)
    return parseFloat(filesize.toFixed(i < 2 ? 0 : dm)) + ' ' + sizes[i]
}

type ExtraPropsGif = {
    create_datetime: string
    // frames: number
} & IGif

const extraProps = (gif: IGif): gif is ExtraPropsGif => {
    return (gif as ExtraPropsGif).create_datetime !== undefined
}

type Props = { gif: IGif; className?: string }
const GifInfo = ({ gif, className }: Props) => {
    const [showMenu, setShowMenu] = useState(false)
    const { width, height, size } = gif.images.original
    // @ts-expect-error TODO: frames
    const { frames } = gif.images.original

    return (
        <div className={`text-giphyWhite relative mt-1 flex gap-2 py-4 ${className}`}>
            <figcaption className="text-giphyLightGrey text-sm font-semibold">{gif.title}</figcaption>
            <div onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? (
                    <CloseIcon height={18} color={giphyLightGrey} className="cursor-pointer" />
                ) : (
                    <EllipsisIcon height={20} color={giphyLightGrey} className="ml-2 rotate-90 cursor-pointer" />
                )}
            </div>
            {showMenu && (
                <div style={{ width: desktopGifWidth }} className="absolute -bottom-0 translate-y-full p-4">
                    <div className="bg-giphyBlack bg-opacity-90">
                        <div className="flex gap-4 p-3">
                            <div className="flex flex-1 flex-col gap-1">
                                <div>
                                    Source:{''}
                                    <span>
                                        {width} x {height} px
                                    </span>
                                </div>

                                {size && (
                                    <div>
                                        Size: <span>{formatSize(parseFloat(size))}</span>
                                    </div>
                                )}
                                <div>
                                    Frames: <span>{frames || ''}</span>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col gap-1">
                                {extraProps(gif) && (
                                    <div>
                                        Uploaded:
                                        <span>{new Date(gif.create_datetime).toLocaleDateString()}</span>
                                    </div>
                                )}
                                <div>
                                    Rating: <span>{(gif.rating || 'unrated').toUpperCase()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default GifInfo
