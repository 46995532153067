'use client'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { iconClassName } from '@/app/components/detail/menu-icon-css'
import DownloadIcon from '@/app/icons/download'
import EmbedIcon from '@/app/icons/embed'
import ShareIcon from '@/app/icons/share'
import { cn } from '@/util/cn'
import { useContext } from 'react'

type Props = { className?: string; iconSize?: number; iconClassName?: string }

export const EmbedToggle = ({ className = '', iconSize, iconClassName: _iconClassName }: Props) => {
    const { setPanel, panel } = useContext(GifPanelContext)
    return (
        <>
            <div
                className={className}
                onClick={() => {
                    setPanel(panel === 'embed' ? 'none' : 'embed')
                }}
            >
                <EmbedIcon height={iconSize} className={cn(iconClassName, _iconClassName)} />
                <label>Embed</label>
            </div>
        </>
    )
}

export const DownloadToggle = ({ className = '', iconSize, iconClassName: _iconClassName }: Props) => {
    const { setPanel, panel } = useContext(GifPanelContext)
    return (
        <div className={className} onClick={() => setPanel(panel === 'download' ? 'none' : 'download')}>
            <DownloadIcon height={iconSize} className={cn(iconClassName, _iconClassName)} />
            <label>Download</label>
        </div>
    )
}

export const ShareToggle = ({ className = '', iconSize, iconClassName: _iconClassName }: Props) => {
    const { setPanel, panel } = useContext(GifPanelContext)
    return (
        <div className={className} onClick={() => setPanel(panel === 'share' ? 'none' : 'share')}>
            <ShareIcon height={iconSize} className={cn(iconClassName, _iconClassName)} />
            <label className="hidden text-lg font-bold md:block">Share</label>
        </div>
    )
}
