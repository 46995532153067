'use client'
import { IGif } from '@giphy/js-types'
import { useState } from 'react'
import TagPill from 'ui/src/components/tag-pill'

type Props = { gif: IGif }
const showMoreAtTagCount = 4
const Tags = ({ gif }: Props) => {
    const [showMore, setShowMore] = useState(false)
    const tags = showMore ? gif.tags : [...gif.tags].slice(0, showMoreAtTagCount)
    return (
        <div className="mb-5 flex gap-1.5">
            <div className={`flex w-3/4 flex-wrap gap-1.5`}>
                {tags.map((tag) => (
                    <TagPill href={`/explore/${encodeURIComponent(tag)}`} key={tag} text={`#${tag}`} />
                ))}
                {gif.tags.length > showMoreAtTagCount
                    ? !showMore && (
                          <div onClick={() => setShowMore(true)}>
                              <TagPill text="..." className="cursor-pointer" />
                          </div>
                      )
                    : null}
            </div>
        </div>
    )
}
export default Tags
