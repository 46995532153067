import { SVGAttributes } from 'react'

const ViewIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 30 18" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.8975 8.68375C30.0342 8.89458 30.0342 9.10542 29.8975 9.31625C29.8096 9.43924 29.6826 9.60395 29.5166 9.8104C29.3506 10.0168 28.9868 10.4209 28.4253 11.0227C27.8638 11.6245 27.2827 12.1999 26.6821 12.7489C26.0815 13.298 25.3223 13.9063 24.4043 14.5739C23.4863 15.2416 22.5635 15.8148 21.6357 16.2936C20.708 16.7723 19.6582 17.1764 18.4863 17.5059C17.3144 17.8353 16.1523 18 15 18C14.1016 18 13.1934 17.9012 12.2754 17.7035C11.3574 17.5059 10.5029 17.2401 9.71192 16.9063C8.9209 16.5725 8.14698 16.1881 7.39014 15.7533C6.6333 15.3184 5.94239 14.8616 5.31738 14.3829C4.69238 13.9041 4.10157 13.4231 3.54492 12.94C2.98828 12.4568 2.50244 11.9978 2.0874 11.563C1.67236 11.1281 1.31348 10.7438 1.01074 10.41C0.708007 10.0761 0.478517 9.8082 0.322266 9.60615L0.10254 9.31625C-0.0341799 9.10542 -0.0341799 8.89458 0.10254 8.68375C0.190431 8.56076 0.317382 8.39605 0.483399 8.1896C0.649415 7.98316 1.01318 7.57907 1.57471 6.97731C2.13623 6.37555 2.71728 5.80015 3.31787 5.2511C3.91846 4.70205 4.67773 4.09371 5.5957 3.42606C6.51368 2.75842 7.43652 2.18521 8.36426 1.70644C9.292 1.22767 10.3418 0.823574 11.5137 0.494144C12.6856 0.164713 13.8477 0 15 0C16.1523 0 17.3096 0.158125 18.4717 0.474378C19.6338 0.790631 20.6885 1.20132 21.6357 1.70644C22.583 2.21157 23.4936 2.7694 24.3677 3.37994C25.2417 3.99049 26.0034 4.60102 26.6528 5.21157C27.3023 5.82211 27.8687 6.37994 28.3521 6.88507C28.8355 7.39019 29.1992 7.80088 29.4434 8.11713L29.8242 8.59151L29.8975 8.68375ZM15 14.06C16.5527 14.06 17.8784 13.5659 18.9771 12.5776C20.0757 11.5893 20.625 10.3968 20.625 9C20.625 7.612 20.0732 6.42167 18.9697 5.42899C17.8662 4.43631 16.543 3.93997 15 3.93997C13.4473 3.93997 12.1216 4.43411 11.0229 5.4224C9.92431 6.41069 9.375 7.60321 9.375 9C9.375 10.4056 9.92187 11.6003 11.0156 12.5842C12.1094 13.5681 13.4375 14.06 15 14.06Z"
        />
    </svg>
)

export default ViewIcon
