'use client'
import { giphyDarkestGrey, giphyLightestGrey, giphyWhite } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import styled from 'styled-components'
import { SourceIcon } from 'ui/src/icons/utility'
import { getSourceUrl } from '../util'

const Container = styled.a`
    margin-right: 10px;
`
const IconContainer = styled.div`
    display: flex;
    background: ${giphyDarkestGrey};
    justify-content: center;
    align-items: center;
    float: left;
    margin-right: 10px;
`
const SourceContainer = styled.div`
    line-height: 18px;
    font-size: 14px;
    display: flex;
    height: 50px;
    justify-content: center;
    flex-direction: column;
`
const SourceLabel = styled.div`
    color: ${giphyLightestGrey};
`
const SourceTld = styled.div`
    font-size: 18px;
    color: ${giphyWhite};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Source = ({ gif }: { gif: IGif }) => {
    const url = getSourceUrl(gif)
    return url ? (
        <Container href={url}>
            <IconContainer className="size-[50px]">
                <SourceIcon height={25} width={25} />
            </IconContainer>
            <SourceContainer>
                <SourceLabel>Source</SourceLabel>
                <SourceTld>{gif.source_tld}</SourceTld>
            </SourceContainer>
        </Container>
    ) : null
}
export default Source
