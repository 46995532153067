'use client'
import { useState } from 'react'

type Props = { description: string }
const truncateAt = 110
const descriptionClassName = 'text-giphyLightGrey max-w-52 text-sm'
const Description = ({ description }: Props) => {
    const [showDisclosure, setDisclosure] = useState(false)
    if (description.length > truncateAt) {
        return (
            <div className={descriptionClassName}>
                <div className={`${showDisclosure ? '' : 'line-clamp-3'}`}>
                    {showDisclosure ? description : description.substring(0, truncateAt)}
                </div>
                <div onClick={() => setDisclosure(!showDisclosure)} className="cursor-pointer font-semibold">
                    {showDisclosure ? 'Read Less ▴' : 'Read More ▾'}
                </div>
            </div>
        )
    }
    return <div className={descriptionClassName}>{description}</div>
}
export default Description
