'use client'
import { NavigateLeftIcon, NavigateRightIcon } from 'ui/src/icons/utility'
import { IGif } from '@giphy/js-types'
import { useRelatedGifPagination } from './use-related-pagination'
import { useRouter } from 'next/navigation'
import { relativeUrl } from '@/util/url'
type Props = { gif: IGif }
const DesktopPagination = ({ gif }: Props) => {
    const { next, prev } = useRelatedGifPagination(gif)
    const router = useRouter()
    return (
        <div className="mt-[13px] flex items-center gap-1">
            <div
                className={`bg-giphyCharcoal flex h-[22px] w-[32px] items-center justify-center ${
                    !prev ? 'pointer-events-none bg-opacity-30' : 'cursor-pointer'
                }`}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    router.push(relativeUrl(prev.url))
                }}
            >
                <NavigateLeftIcon height={13} />
            </div>
            <div
                className={`bg-giphyCharcoal flex h-[22px] w-[32px] items-center justify-center ${
                    !next ? 'pointer-events-none bg-opacity-30' : 'cursor-pointer'
                }`}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    router.push(relativeUrl(next.url))
                }}
            >
                <NavigateRightIcon height={13} />
            </div>
        </div>
    )
}
export default DesktopPagination
